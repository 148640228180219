.mydearest__storefrontbody {
    margin-top: 7rem;
    color: var(--color-text-main);
}

.mydearest__storefrontbody h1 {
    display: flex;
    justify-content: center;
    font-size: 3rem;
}

.mydearest__storefrontbody h3 {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
}

.mydearest__storefrontbody h5 {
    /* margin-top: 1rem; */
    display: flex;
    justify-content: center;
}

.mydearest__storefrontbody-container {
    /* background-color: bisque; */
    color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.mydearest__storefrontbody-container_card {
    /* background-color: rgb(95, 58, 131); */
    display: flex;
    /* align-content: flex-start; */
    align-items: flex-start;
    /* flex-direction: row; */
    margin: 2rem;
    padding: 0%;
}

.mydearest__storefrontbody-container_card button {
    background-color: alpha(white, 0.8);
    min-width: 9rem;
    min-height: 3rem;
}