* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg-main);
}

.App {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 50vw 32vh, var(--color-bg-circle) 0%, var(--color-bg-main) 30%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 50vw 32vh, var(--color-bg-circle) 0%, var(--color-bg-main) 30%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 50vw 32vh, var(--color-bg-circle) 0%, var(--color-bg-main) 30%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 50vw 32vh, var(--color-bg-circle) 0%, var(--color-bg-main) 30%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 50vw 32vh, var(--color-bg-circle) 0%, var(--color-bg-main) 30%);

    /* height: 100vh; */
    /* height: 100%; */
    /* width: 100vh; */
    /* display: flex; */
    min-height: 100vh;
}

.section__padding {
    padding: 4rem 6rem;
}
  
.section__margin {
    margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
  
    .section__margin {
        margin: 4rem 2rem;
    }  
  }